import Button = require("Everlaw/UI/Button");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Is = require("Everlaw/Core/Is");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Util = require("Everlaw/Util");
import Win = require("Everlaw/Win");

/*
 * These elements are in this file together to prevent circular dependencies, stemming from
 * SizzleReelDialogs being created by Banners, while also containing a Banner.
 */

interface BannerLink {
    /** The text of the link, defaults to learn more. */
    text?: string;
    /** The link url. */
    url?: string;
    /** If defined, opens the sizzl reel dialog with the given type instead of a new tab. */
    sizzleReelDialogType?: string;
}

type BannerContent = (Dom.Content | BannerLink)[];

interface BannerParams {
    /** Label content for the banner, consisting of content and links. */
    content: BannerContent;
    /** Whether or not the content should be bold, defaults to false. */
    boldContent?: boolean;
    /** If defined, creates a button to the far right of the banner with this label. */
    buttonLabel?: Dom.Content;
    /** If defined, opens a new tab when the optional button is clicked. */
    buttonLink?: string;
    /** The height of the banner, in pixels. */
    height: number;
    /** Left and right margin, defaults to 16. */
    sideMargin?: number;
    /** The font size of the label and linkText, defaults to 14. */
    fontSize?: number;
    /** If defined, places the banner in the parent. */
    parent?: string | Node;
}

function isBannerLink(element: Dom.Content | BannerLink): element is BannerLink {
    const link = element as BannerLink;
    return [link.url || link.sizzleReelDialogType].some(Is.defined);
}

class Banner {
    private node: HTMLElement;
    private toDestroy: Util.Destroyable[] = [];
    constructor(params: BannerParams) {
        const content = this.createContent(params);
        this.node = Dom.create("div", {
            class: "sb-free-limited-feature-banner",
            style: { height: `${params.height}px` },
            content,
        });
        if (params.buttonLabel) {
            const buttonDiv = Dom.create("div", { class: "button-div" }, this.node);
            if (content === null) {
                Dom.style(buttonDiv, { marginRight: "auto" });
            }
            this.toDestroy.push(
                new Button({
                    label: params.buttonLabel,
                    onClick: () => params.buttonLink && !!Win.openExternal(params.buttonLink),
                    parent: buttonDiv,
                    class: "sb-free",
                    width: null,
                    style: { marginRight: content === null ? "0" : this.getSideMargin(params) },
                }),
            );
        }
        params.parent && Dom.place(this.node, params.parent);
    }
    private createContent(params: BannerParams) {
        if (!Is.defined(params.content)) {
            return null;
        }
        const content = Dom.create("div", {
            class: params.boldContent ? "semi-bold" : "",
            content: this.getLabelElements(params.content),
        });
        const callout = Icon.callout("crown-filled-yellow", content, false);
        Dom.style(callout, {
            marginLeft: this.getSideMargin(params),
            fontSize: `${params.fontSize || 14}px`,
        });
        return callout;
    }
    private getSideMargin(params: BannerParams) {
        return `${params.sideMargin || 16}px`;
    }
    private getLabelElements(content: BannerContent) {
        return content.map((element) =>
            isBannerLink(element) ? this.createLinkElement(element) : element,
        );
    }
    private createLinkElement(element: BannerLink) {
        const link = Dom.a({ class: "help-link" }, element.text || "Learn more");
        if (element.sizzleReelDialogType) {
            link.onclick = () => {
                showSizzleReelDialog(element.sizzleReelDialogType);
            };
        } else {
            link.href = element.url;
            link.rel = "noopener noreferrer";
            link.target = "_blank";
        }
        return link;
    }
    getNode() {
        return this.node;
    }
    destroy() {
        Util.destroy(this.toDestroy);
    }
}

interface BanneredDialogParams {
    dialogParams: QueryDialog.Params;
    bannerParams: BannerParams;
    bannerArea: "prompt" | "body";
}

/**
 * A QueryDialog with a SbFreeLimitedFeatureBanner as the QueryDialog prompt or body.
 */
export class BanneredDialog extends QueryDialog {
    constructor(params: BanneredDialogParams) {
        const classes = params.dialogParams.classes;
        params.dialogParams.classes = `sb-free-bannered-dialog ${classes ? " " + classes : ""}`;
        const banner = new Banner(params.bannerParams).getNode();
        if (params.bannerArea === "prompt") {
            Dom.style(banner, { marginTop: "-16px" });
            params.dialogParams.prompt = banner;
        } else {
            params.dialogParams.body = banner;
        }
        super(params.dialogParams);
    }
}

interface SizzleReelDialogParams {
    text?: BannerContent;
    buttonText: string;
}
const SIZZLE_REEL_DIALOG_TYPES: { [key: string]: SizzleReelDialogParams } = {
    "limited-feature": {
        text: ["To access all features, get an ", getEverlawLink(), " subscription"],
        buttonText: "Get in touch",
    },
    general: {
        text: ["See how ", getEverlawLink(), " can work for your team"],
        buttonText: "Get in touch",
    },
    contact: {
        buttonText: "Contact us to learn more",
    },
};

class SizzleReelDialog extends Dialog {
    constructor(dialogType = "limited-feature") {
        const video = Dom.create("iframe", {
            width: "640",
            height: "360",
            src: "https://player.vimeo.com/video/388575342",
            frameBorder: 0,
            allowFullscreen: true,
        });
        const params = SIZZLE_REEL_DIALOG_TYPES[dialogType];
        const banner = new Banner({
            content: params.text,
            boldContent: true,
            buttonLabel: params.buttonText,
            buttonLink: "https://www.everlaw.com/request-a-demo/?utm_source=storybuilder",
            height: 90,
            sideMargin: 48,
            fontSize: 16,
        });
        super({
            content: Dom.div(
                Dom.div({ style: { margin: "0 32px 32px" } }, video),
                Dom.node(banner),
            ),
            classes: "sb-free-limited-feature-dialog",
            destroyOnHide: true,
        });
        this.toDestroy = [video, banner];
    }
    override destroy() {
        super.destroy();
        Util.destroy(this.toDestroy);
    }
}

function getEverlawLink() {
    return {
        text: "Everlaw",
        url: "https://everlaw.com",
    };
}

export function showSizzleReelDialog(dialogType = "limited-feature") {
    new SizzleReelDialog(dialogType).show();
}
