import Context = require("Everlaw/Context/Context");
import { Context as DatabaseContext } from "Everlaw/Database";
import ContextSideBar = require("Everlaw/Context/UI/SideBar");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import SbFreeLimitedFeatureElements = require("Everlaw/Context/SbFree/LimitedFeature/Elements");
import Server = require("Everlaw/Server");
import SideBar = require("Everlaw/UI/SideBar");

const SbFree = new (class extends Context {
    constructor() {
        super(DatabaseContext.SBFREE);
    }
    public override inServerContext() {
        return Server.isSbFree();
    }
    public override SideBar(params: SbFree.SideBarParams) {
        params.inaccessibleNodes = params.inaccessibleNodes || {};
        (params.limitedFeatureNodes || []).forEach((id) => {
            params.inaccessibleNodes[id] = {
                onClick: () => {
                    SbFreeLimitedFeatureElements.showSizzleReelDialog();
                },
                updateNode: (node) => {
                    const iconClass = `crown-filled-yellow${node instanceof SideBar.SBChild ? "-20" : ""}`;
                    // Placing as the first element prevents spacing errors for long side bar node names.
                    Dom.place(new Icon(iconClass, {}), node.element, 0);
                },
            };
        });
        return super.SideBar(params);
    }
})();

module SbFree {
    export interface SideBarParams extends ContextSideBar.Params {
        limitedFeatureNodes?: string[];
    }
}

export = SbFree;
